import { useEffect } from "react"

//
// Custom hook to warn about rerenders.
//
export const useRerenderWarning = (componentName) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      // console.warn(`RERENDER: ${componentName}`)
    }
  })
}
