import { useUserContext } from "context/userContext"
import { useProfileContext } from "context/profileContext"
import { navigate } from "gatsby"
import { useRerenderWarning } from "hooks/useRerenderWarning"
import React, { useEffect, useState } from "react"
import routes from "services/routes"

//
// Protected routes ensure that a user is authenticated before the route can be viewed
//
const ProtectedRoute = ({ component: Component, location, ...rest }) => {
  // Get auth state from the context
  const { hasLoaded, userId } = useUserContext()

  // Get the profile from the context
  const { activeProfileId } = useProfileContext()

  // Component to render
  const [protectedComponent, setProtectedComponent] = useState(null)

  useRerenderWarning("ProtectedRoute")

  useEffect(() => {
    // Prevent infinite loop
    if (protectedComponent) {
      return
    }
    // Extract route protection status from environment config
    const protectionEnabled = process.env.GATSBY_ROUTE_PROTECTION === "true"
    if (protectionEnabled === null) {
      throw new Error(
        "Gatsby environment config missing 'GATSBY_ROUTE_PROTECTION' key!"
      )
    }

    // Toggle route protection in environment settings for development
    if (!protectionEnabled) {
      console.warn("Route protection disabled!")
      setProtectedComponent(<Component {...rest} />)
      return
    }

    if (!hasLoaded) {
      // Auth library still loading, so don't do anything yet...
    } else if (hasLoaded && !userId) {
      // Auth library loaded and user is NOT authenticated; redirect to sign-in
      navigate(routes.SIGN_IN, { replace: true })
    } else if (hasLoaded && userId) {
      // Auth library loaded and user IS authenticated; check other metadata first...
      setProtectedComponent(<Component {...rest} />)
    } else
      throw Error(
        `Could not establish state for protected route: ${location.pathname}`
      )
  }, [protectedComponent, location, hasLoaded, userId, activeProfileId, rest])

  return protectedComponent
}

export default ProtectedRoute
