import { Redirect, Router } from "@reach/router"
import ProtectedRoute from "components/utils/protectedRoute"
import { isSSR } from "helpers/ssrCheck"
import React, { lazy, Suspense } from "react"
import routes from "services/routes"

// 404: Not Found
const NotFound = lazy(() => import("components/utils/notFound"))

// Auth
const AuthAction = lazy(() => import("app/auth/authAction"))
const SignIn = lazy(() => import("app/auth/sign-in/sign-in"))
const SignUp = lazy(() => import("app/auth/sign-up/sign-up"))
const ResetPasswordStep1 = lazy(() =>
  import("app/auth/resetPassword/reset-password-step-1")
)
const ResetPasswordStep2 = lazy(() =>
  import("app/auth/resetPassword/reset-password-step-2")
)

// Account
const AccountOverview = lazy(() => import("app/account/accountOverview"))
const PaymentDetails = lazy(() => import("app/account/paymentDetails"))
const Purchases = lazy(() => import("app/account/purchases"))

// Payment
const BankAuthComplete = lazy(() => import("app/payment/bankAuthComplete"))

// Setup
const Setup1Confirmation = lazy(() =>
  import("app/setup/setup-1-confirmation.jsx")
)
const Setup2ForGrownUps = lazy(() =>
  import("app/setup/setup-2-for-grown-ups.jsx")
)
const Setup3ForChildren = lazy(() =>
  import("app/setup/setup-3-for-children.jsx")
)
const Setup4Profile = lazy(() => import("app/setup/setup-4-profile.jsx"))
const Setup8Device = lazy(() => import("app/setup/setup-8-device.jsx"))
const Setup9GetPlayer = lazy(() => import("app/setup/setup-9-player-get.jsx"))
const Setup10RegisterPlayer = lazy(() =>
  import("app/setup/setup-10-player-register.jsx")
)
const Setup6GetContent = lazy(() => import("app/setup/setup-11-content.jsx"))

// Profiles
const ChooseProfile = lazy(() => import("app/profiles/choose/chooseProfile"))
const EditProfiles = lazy(() => import("app/profiles/edit/editProfiles"))
const ChooseAvatar = lazy(() => import("app/profiles/choose/chooseAvatar"))
const CreateProfile = lazy(() => import("app/profiles/create/createProfile"))
const EditProfile = lazy(() => import("app/profiles/edit/editProfile"))

// Library
const Library = lazy(() => import("app/library/library"))

//
// Strip the leading "/app" from the route URLs
// for the nested router.
//
const stripApp = (route) => {
  return route.replace("/app", "")
}

const App = () => {
  return (
    <>
      {!isSSR && (
        // TODO: sort out loading view
        <Suspense fallback={<span />}>
          <Router basepath="/app">
            {/*
             * UNPROTECTED ROUTES (no authenticated user required)
             * ==================================================
             */}

            {/* 404 Not Found */}
            <NotFound default />

            {/* Auth Action */}
            <AuthAction path={stripApp(routes.AUTH_ACTION)} />

            {/* Sign in */}
            <SignIn path={`${stripApp(routes.SIGN_IN)}:stepId`} />
            <Redirect from={stripApp(routes.SIGN_IN)} to="step-1" noThrow />

            {/* Sign up */}
            <SignUp path={`${stripApp(routes.SIGN_UP)}:stepId`} />
            <Redirect from={stripApp(routes.SIGN_UP)} to="step-1" noThrow />

            {/* Reset password, Step 1 */}
            <ResetPasswordStep1 path={stripApp(routes.RESET_PASSWORD_STEP_1)} />

            {/* Reset password, Step 2 */}
            <ResetPasswordStep2 path={stripApp(routes.RESET_PASSWORD_STEP_2)} />

            {/* Choose Avatar */}
            <ChooseAvatar path={stripApp(routes.CHOOSE_AVATAR)} />

            {/*
             * PROTECTED ROUTES (user must be authenticated)
             * =============================================
             */}

            {/*
             * SETUP
             * -------------------------------
             */}

            {/* Setup 1: Account creation confirmation */}
            <ProtectedRoute
              component={Setup1Confirmation}
              path={stripApp(routes.SETUP_1_CONFIRMATION)}
            />

            {/* Setup 2: Platform overview for grown-ups */}
            <ProtectedRoute
              component={Setup2ForGrownUps}
              path={stripApp(routes.SETUP_2_FOR_GROWN_UPS)}
            />

            {/* Setup 3: Platform overview for children */}
            <ProtectedRoute
              component={Setup3ForChildren}
              path={stripApp(routes.SETUP_3_FOR_CHILDREN)}
            />

            {/* Setup 4: Create a profile */}
            <ProtectedRoute
              component={Setup4Profile}
              path={stripApp(routes.SETUP_4_PROFILE)}
            />

            {/* Setup 5: Choose profile avatar */}
            <ProtectedRoute
              component={ChooseAvatar}
              path={stripApp(routes.SETUP_5_PROFILE_AVATAR)}
            />

            {/* Setup 6: Create profile */}
            <ProtectedRoute
              component={CreateProfile}
              path={stripApp(routes.SETUP_6_PROFILE_CREATE)}
            />

            {/* Setup 7: Add another profile */}
            <ProtectedRoute
              component={ChooseProfile}
              path={stripApp(routes.SETUP_7_PROFILE_ADD)}
            />

            {/* Setup 8: Choose a device */}
            <ProtectedRoute
              component={Setup8Device}
              path={stripApp(routes.SETUP_8_DEVICE)}
            />

            {/* Setup 9: Get player app */}
            <ProtectedRoute
              component={Setup9GetPlayer}
              path={stripApp(routes.SETUP_9_PLAYER_GET)}
            />

            {/* Setup 10: Register player app */}
            <ProtectedRoute
              component={Setup10RegisterPlayer}
              path={stripApp(routes.SETUP_10_PLAYER_REGISTER)}
            />

            {/* Setup 11: Get content */}
            <ProtectedRoute
              component={Setup6GetContent}
              path={stripApp(routes.SETUP_11_GET_CONTENT)}
            />

            {/*
             * PROFILES
             * -------------------------------
             */}

            {/* Choose Profile */}
            <ProtectedRoute
              component={ChooseProfile}
              path={stripApp(routes.CHOOSE_PROFILE)}
            />

            {/* Edit Profiles */}
            <ProtectedRoute
              path={stripApp(routes.EDIT_PROFILES)}
              component={EditProfiles}
            />

            {/* Create Profile */}
            <ProtectedRoute
              path={stripApp(routes.CREATE_PROFILE)}
              component={CreateProfile}
            />

            {/* Choose Profile Avatar */}
            <ProtectedRoute
              path={stripApp(routes.CHOOSE_AVATAR)}
              component={ChooseAvatar}
            />

            {/* Edit Profile */}
            <ProtectedRoute
              path={stripApp(routes.EDIT_PROFILE)}
              component={EditProfile}
            />

            {/*
             * ACCOUNT
             * -------------------------------
             */}

            {/* Account overview */}
            <ProtectedRoute
              path={stripApp(routes.ACCOUNT_OVERVIEW)}
              component={AccountOverview}
            />

            {/* Payment Details */}
            <ProtectedRoute
              path={stripApp(routes.PAYMENT_DETAILS)}
              component={PaymentDetails}
            />

            {/* Purchases */}
            <ProtectedRoute
              path={stripApp(routes.PURCHASES)}
              component={Purchases}
            />

            {/*
             * PAYMENT
             * -------------------------------
             */}

            {/* Bank authentication complete */}
            <ProtectedRoute
              path={stripApp(routes.BANK_AUTH_COMPLETE)}
              component={BankAuthComplete}
            />

            {/*
             * LIBRARY
             * -------------------------------
             */}

            {/* Library */}
            <ProtectedRoute
              path={stripApp(routes.LIBRARY)}
              component={Library}
            />

            {/* Library > Audiobooks */}
            <ProtectedRoute
              path={stripApp(routes.LIBRARY_AUDIOBOOKS)}
              component={Library}
            />

            {/* Library > Podcasts */}
            <ProtectedRoute
              path={stripApp(routes.LIBRARY_PODCASTS)}
              component={Library}
            />

            {/* Library > Stations */}
            <ProtectedRoute
              path={stripApp(routes.LIBRARY_STATIONS)}
              component={Library}
            />
          </Router>
        </Suspense>
      )}
    </>
  )
}

export default App
